import React, { Component } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { withRouter } from 'react-router-dom';

import {
  Table,
  sandboxWritersStorePack,
  pluginConfigs,
  mainHubActions,
  controls,
  utils,
  profileStorePack,
  sandboxSettingsStorePack,
  notificationsEntityStorePack,
  apiUrls,
  sandboxFCMOperatorsStorePack,
  modals,
} from '@verdaccio/crminfo';

import AssignFCMModal from '../components/AssignFCMModal';

import stylesWriter from './writer-page.styl';
import styles from './sandbox-page.styl';
import authFetch, { authFetchFormData } from './../utils/authFetch';


const STATUSES = ['new', 'meeting', 'hired', 'hidden', 'deleted', 'outdated', 'sanctioned'].map(s => [s, s]);
const getName = user => (user.profile && ([user.profile.first_name, user.profile.last_name].join(' ').trim() || user.profile.phone)) || user.email;
const STAGES = [
  'registration',
  'applicant_info',
  'applicant_info_major_subjects',
  'applicant_info_second_subjects',
  'applicant_first_preferred_subject',
  'applicant_info_second_subjects',
  'application_overview',
  'email_verification',
  'welcome_page',
  'language_test',
  'phone_verification',
  'essay_welcome',
  'subject_essay_1',
  'essay_1',
  'essay_2',
  'essay_3',
  'essay_4',
  'essay_5',
  'sandbox_completed',
  'subject_essay_1_success',
  'await_result',
  'language_test_failed',
  'essay_1_failed',
  'essay_2_failed',
  'essay_3_failed',
  'hired',
  'declined',
  'tutorial',
  'tutorial_started',
  'academic_test',
  'essay_1_success',
  'phone_verification_2',
  'essay_1_success_v0',
  'essay_2_success_v0',
  'subject_test',
  'subject_test_start',
  'subject_test_success',
  'subject_test_failed',
  'math_test',
  'math_test_start',
  'finance_test',
  'finance_test_start',
  'finance_text_test',
  'finance_text_test_start',
].map(s => [s, s]);


const SCENARIOS = [
  'v0',
  'v1',
  'v2',
  'v3',
  'v4',
  'v5',
  'v6',
  'v7',
  'v8',
  'v9'
].map(s => ['v' + (Number([...s].slice(1)) + 1), s]);

const MAJOR_SUBJECTS = {
  social: 'Social sciences',                                                                                                                                                          
  engineering: 'Physics & Engineering',                                                                                                                                               
  math: 'Math & Statistics',                                                                                                                                                          
  humanities: 'Humanities',                                                                                                                                                           
  finance: 'Finance & Economics',                                                                                                                                                     
  environmental: 'Environmental science',                                                                                                                                             
  literature: 'English & Literature',                                                                                                                                                 
  it: 'Computer science & IT',                                                                                                                                                        
  chemistry: 'Chemistry',                                                                                                                                                             
  business: 'Business Studies', 
};

const MAJOR_SUBJECTS_MAP = Object.keys(MAJOR_SUBJECTS).map((subject) => [MAJOR_SUBJECTS[subject], subject]);
let intervalId = null;

class SandboxPage extends Component {
  static getMajorSubjects(item) {
    return item.profile.applicant_info &&
          item.profile.applicant_info.major_subjects && 
          item.profile.applicant_info.major_subjects.length > 0 ? 
          item.profile.applicant_info.major_subjects[0] : '';
  }
  constructor(props) {
    super(props);
    this.state = {
      isApplicantsListLoaded: false,
      isCsvLoading: false,
      isErrorUploadList: false,
      applicantsHiringStatus: '',
      applicantsHiringData: {},
      FCMoperatorsCount: 0,
      unassignedCount: 0,
    };

    this.setSortingOrReset = this.setSortingOrReset.bind(this);
    this.onLoadApplicantsList = this.onLoadApplicantsList.bind(this);
    this.onUploadApplicantsList = this.onUploadApplicantsList.bind(this);
    this.onOpenHiringApplicantsResults = this.onOpenHiringApplicantsResults.bind(this);
    this.onRunApplicants = this.onRunApplicants.bind(this);
    this.getApplicantsList = this.getApplicantsList.bind(this);
    this.getStatus = this.getStatus.bind(this);
    this.onAssignFCMClick = this.onAssignFCMClick.bind(this);
    this.onUpdateStatus = this.onUpdateStatus.bind(this);
  }

  componentDidMount() {
    this.getApplicantsList();
    this.getFcmOperatorsCount();
    this.props.loadNotifications();
    const { entityId } = this.props.match.params;
    if (entityId) {
      this.props.pluginSetConfig(pluginConfigs.SandboxWriterPlugin({ entityId, options: { closeUrl: '/applicants' } }));
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const entityId = this.props.match.params.entityId;
    const prevEntityId = prevProps.match.params.entityId;
    if (entityId !== prevEntityId && entityId) {
      this.props.pluginSetConfig(pluginConfigs.SandboxWriterPlugin({ entityId, options: { closeUrl: '/applicants' } }));
    }

    const { applicantsHiringStatus: prevApplicantsHiringStatus } = prevState
    const { applicantsHiringStatus } = this.state;

    if (prevApplicantsHiringStatus !== applicantsHiringStatus && applicantsHiringStatus === 'running' && !intervalId) {
      intervalId = setInterval(() => {
        this.getApplicantsList();
      }, 10000);
    }

    if (prevApplicantsHiringStatus !== applicantsHiringStatus && applicantsHiringStatus !== 'running' && intervalId) {
      clearInterval(intervalId);
      intervalId = null;
    }
  }

  getFcmOperatorsCount() {
    const { sandboxFCMOperatorsCount } = apiUrls;
    authFetch(sandboxFCMOperatorsCount)
      .then((operatorsCount = {}) => {
        const { total = 0, unassigned_count } = operatorsCount;
        this.setState({
          FCMoperatorsCount: total,
          unassignedCount: unassigned_count
        });
      })
      .catch(err => {
        console.error(err);
      });
  }

  getApplicantsList() {
    const { sandboxImportApplicantsList } = apiUrls;
    return authFetch(sandboxImportApplicantsList)
      .then((data) => {
        const { import: __import } = data;
        
        let hiringStatus = 'notLoaded';
        
        if (Boolean(__import)) {
          const { status } = __import;
          hiringStatus = this.getStatus(status);
        }

        this.setState({
          isApplicantsListLoaded: true,
          applicantsHiringStatus: hiringStatus,
          applicantsHiringData: __import,
        });
      })
  }


  getStatus(status) {
    const applicantsResultsShowed = localStorage.getItem('applicants_results_showed');
          
    if (status === 'success') {
      return applicantsResultsShowed ? 'notLoaded' : 'success';
    }

    return status
  }

  setSortingOrReset(fieldName, isAsc) {
    if (isAsc === null) {
      this.props.setSorting(null, true);
    } else {
      this.props.setSorting(fieldName, isAsc);
    }
  }

  onAssignFCMClick() {
    const { addModal } = this.props;
    const { FCMoperatorsCount, unassignedCount } = this.state;
    addModal(<AssignFCMModal FCMoperatorsCount={FCMoperatorsCount} unassignedCount={unassignedCount} />);
  }

  onClickRow(item) {
    const routeEntityId = this.props.match.params.entityId;
    routeEntityId === item.id
      ? this.props.pluginSetConfig(pluginConfigs.SandboxWriterPlugin({ entityId: item.id, options: { closeUrl: '/applicants' } }))
      : this.props.history.push('/applicants/' + item.id);
  }

  onLoadApplicantsList() {
    this.inputFileRef.click();
  }

  onUploadApplicantsList(e) {
    const { sandboxImportApplicantsListUpload } = apiUrls;
    const { target: { files } } = e;
    const formData = new FormData();
    formData.append('file', files[0]);
    this.setState({
      isCsvLoading: true,
    })
    authFetchFormData(sandboxImportApplicantsListUpload, {
      'method': 'POST',
      body: formData
    })
    .then((data) => {
      const { import: __import } = data;
      const { status = 'modeling' } = __import;
      this.setState({
        isCsvLoading: false,
        applicantsHiringData: __import,
        applicantsHiringStatus: status,
        isErrorUploadList: false,
      });
    })
    .catch(() => {
      this.setState({
        isCsvLoading: false,
        isErrorUploadList: true,
      });
    })
    .finally(() => {
      this.inputFileRef.value = null;
    })
  }

  onRunApplicants(data) {
    const { status } = data
    this.setState({
      applicantsHiringStatus: status,
    });
  }

  onOpenHiringApplicantsResults() {
    const { applicantsHiringData, applicantsHiringStatus } = this.state;
    const { openHiringApplicantsResults } = this.props;
    openHiringApplicantsResults(applicantsHiringData, this.onRunApplicants, this.getApplicantsList);
    if (applicantsHiringStatus === 'success') {
      localStorage.setItem('applicants_results_showed', true);
      this.setState({
        applicantsHiringStatus: 'notLoaded',
      });
    } else {
      localStorage.removeItem('applicants_results_showed');
    }
  }

  onUpdateStatus(id, value) {
    const { updateStatus, addModal } = this.props;
    updateStatus(id, value)
      .catch((err) => {
        if (err && err.responseInstance) {
          err.responseInstance.text().then((text = '') => {
            try {
              const errJson = JSON.parse(text) || {};
              const { errors = [] } = errJson;
              addModal(<modals.WarningModal title="Can’t be hired!" buttonName="Got it" message={errors.length > 0 ? errors : 'Error while updating status'} />);
            } catch (e) {
              console.error('error while parse ', text);
            }
          });
        }
      })
  }

  renderStatus(item) {
    switch (item.status) {
      case 'new':
      case 'meeting':
      case 'hidden':
      case 'deleted':
      case 'outdated':
        return (
          <span onClick={e => e.stopPropagation()}>
            <controls.DropdownSelect
              value={item.status}
              values={STATUSES}
              onDropdownChange={value => this.onUpdateStatus(item.id, value)}
              hideAll
            />
          </span>
        );
      case 'hired':
      case 'sanctioned':
        return (<controls.Button disabled>{item.status}</controls.Button>);
      default:
        return null;
    }
  }

  renderAssignBtn() {
    const { FCMoperatorsCount } = this.state;

    if (FCMoperatorsCount === 0) {
      return (
        <span className={stylesWriter.tooltip} >
            <span className={stylesWriter.tooltip_trigger}>
              <controls.Button onClick={this.onAssignFCMClick} disabled>
                Assign FCMs
              </controls.Button>
            </span>
            <span className={classnames(stylesWriter.tooltip_content, stylesWriter.tooltip_title)}>
              No applicants with status new and stage await_result were found.
            </span>
        </span>
      )
    }

    return (
      <controls.Button onClick={this.onAssignFCMClick}>
        Assign FCMs
      </controls.Button>
    );
  }

  render() {
    if (this.props.notificationsLoading) {
      return null;
    }

    const { isApplicantsListLoaded, applicantsHiringStatus, isCsvLoading, isErrorUploadList } = this.state;
    const { fcmOperators, loadFCMOperators } = this.props;
    const applicantsHiringMap = {
      notLoaded: {
        btnName: 'Add hiring list',
        handler: this.onLoadApplicantsList,
        primary: true
      },
      modeling: {
        btnName: 'Show hiring results',
        handler: this.onOpenHiringApplicantsResults,
      },
      running: {
        btnName: 'Hiring in progress...',
        noBtn: true,
      },
      success: {
        btnName: 'Show hiring results',
        handler: this.onOpenHiringApplicantsResults,
      }
    }
    const applicantsHiringBtn = applicantsHiringMap[applicantsHiringStatus];

    const { btnName, primary , handler, noBtn } = applicantsHiringBtn || {};
    

    const tableConfig = {
      onClickRow: item => this.onClickRow(item),
      fields: [
        {
          name: 'Name',
          formatter: item => getName(item),
          width: 2,
        },
        {
          name: 'Phone',
          formatter: item => {
            if (!item.profile) {
              return;
            }
            // return (<span className={item.profile.phone_verification ? styles.confirmed : null}>{item.profile.phone}</span>);
            return (
              < span className={stylesWriter.tooltip} >
                <span className={stylesWriter.tooltip_trigger}>
                  <span className={classnames(stylesWriter.order_ellipsis, { [styles.confirmed]: item.profile.phone_verification })}>{item.profile.phone}</span>
                </span>
                <span className={classnames(stylesWriter.tooltip_content, stylesWriter.tooltip_title)}>
                  {item.profile.phone}
                </span>
              </span >
            )
          },
          width: 1,
        },
        {
          name: 'Status',
          formatter: item => this.renderStatus(item),
          filterConfig: {
            value: this.props.filter.status,
            options: STATUSES,
            onChange: value => this.props.setFilter('status', value),
          },
          width: 1.5,
          classnames: ['centered'],
        },
        {
          name: 'Stage',
          formatter: item => item.profile.is_anonymous ? <div className={stylesWriter.stage}>{item.stage}<i>BLOCKED</i></div> : item.stage,
          filterConfig: {
            value: this.props.filter.stages,
            options: STAGES,
            isMultiple: true,
            onChange: value => this.props.setFilter('stages', value),
          },
          width: 2,
        },
        {
          name: 'Language test',
          formatter: item => item.profile.language_test_score || '',
          classnames: ['centered'],
          sortingConfig: {
            name: 'language_test_score',
            onChange: isAsc => this.setSortingOrReset('language_test_score', isAsc),
          },
          width: 1.2,
        },
        {
          name: 'Subject test',
          formatter: item => item.profile.subject_test_score || '',
          classnames: ['centered'],
          sortingConfig: {
            name: 'subject_test_score',
            onChange: isAsc => this.setSortingOrReset('subject_test_score', isAsc),
          },
          width: 1.2,
        },
        {
          name: 'Subject',
          formatter: item => MAJOR_SUBJECTS[item.profile.subject_type] || '',
          filterConfig: {
            value: this.props.filter.subject_types,
            options: MAJOR_SUBJECTS_MAP,
            isMultiple: true,
            onChange: value => this.props.setFilter('subject_types', value),
          },
          width: 1.5,
        },
        {
          name: 'Script',
          formatter: item => 'v' + (Number([...item.scenario_version].slice(1)) + 1),
          filterConfig: {
            value: this.props.filter.scenario_version,
            options: SCENARIOS,
            onChange: value => this.props.setFilter('scenario_version', value),
          },
          width: 0.8,
          classnames: ['centered'],
        },
        {
          name: 'IP',
          formatter: item => {
            if (!item.profile) {
              return null;
            }
            return (
              <div>
                {item.profile.register_ip}
                {!!item.profile.register_ip && <br />}
                {item.profile.geoip_country_name}
              </div>
            );
          },
          filterConfig: {
            value: this.props.filter.geoip_country_name,
            isMultiple: true,
            options: this.props.countryOptions,
            onChange: value => this.props.setFilter('geoip_country_name', value),
          },
          width: 1.5,
        },
        {
          name: 'Last action',
          formatter: item => {
            const value = item.last_action || item.last_login;
            return value ? utils.formatTime(value, 'dy') : null;
          },
          width: 1.5,
          sortingConfig: {
            name: 'last_action',
            onChange: isAsc => this.setSortingOrReset('last_action', isAsc),
          },
          dateRangeFilterConfig: {
            valueFrom: this.props.filter['last_action_begin'],
            valueTo: this.props.filter['last_action_end'],
            onChange: (valueFrom, valueTo) => {
              this.props.setFilter('last_action_begin', valueFrom)
              this.props.setFilter('last_action_end', valueTo)
            }
          },
        },
        {
          name: 'Ref',
          // formatter: item => item.ref || 'not specified',
          formatter: item => {
            if (item.ref) {
              return (
                <span className={stylesWriter.tooltip}>
                  <span className={stylesWriter.tooltip_trigger}>
                    <span className={classnames(stylesWriter.text_ellipsis, stylesWriter.ellipsis_w)}>{item.ref}</span>
                  </span>
                  <span className={classnames(stylesWriter.tooltip_content, stylesWriter.tooltip_title)}>
                    {item.ref}
                  </span>
                </span>
              )
            } else {
              return item.ref || 'not specified'
            }
          },

          filterConfig: {
            value: this.props.filter.ref,
            isMultiple: true,
            options: this.props.refOptions,
            highOptions: this.props.highRefOptions,
            onChange: value => this.props.setFilter('ref', value),
          },
          width: 1.5,
        },
        {
          name: 'Comment',
          formatter: item => {
            const comment = item.profile && item.profile.comment;
            if (!comment) {
              return null;
            }

            if (comment.length >= 44) {
              return (
                <span className={stylesWriter.tooltip} >
                  <span className={stylesWriter.tooltip_trigger}>
                    <span className={styles.overflow_message}>{comment}</span>
                  </span>
                  <span className={classnames(stylesWriter.tooltip_content, stylesWriter.tooltip_title)}>
                    {comment}
                  </span>
                </span >
              )
            }
            return (<span className={styles.overflow_message}>{comment}</span>);
          },
          width: 2.7,
        },
        {
          name: 'FCM',
          formatter: item => item.fcm_email,

          filterConfig: {
            value: this.props.filter.fcm_emails,
            isMultiple: true,
            getOptions: fcmOperators.length === 0 ? loadFCMOperators : () => { return null },
            options: fcmOperators.map(({ email }) => [email, email]),
            onChange: value => this.props.setFilter('fcm_emails', value),
          },
          ddRight: true,
          width: 2.5,
        },
      ],
    };
    return (
      <div>
        {isApplicantsListLoaded && (
          <div className={styles.btn_container}>
              {this.renderAssignBtn()}
            {
              noBtn ? (
                <div className={styles.btn_container__no_btn}>{btnName}</div>
              ) : (
               <>
                { isErrorUploadList && <div className={styles.btn_container__error_upload}>Error</div> }
                <controls.Button disabled={isCsvLoading} primary={primary} onClick={handler}>
                  {btnName}
                </controls.Button>
               </> 
              )
            }
          </div>
        )}
        <input onChange={this.onUploadApplicantsList} ref={(ref) => { this.inputFileRef = ref; }} type="file" accept=".csv" className={styles.file_input} />
        <Table
          {...this.props}
          tableConfig={tableConfig}
          sticky
          stickyThreshold="58px"
          mainLoader
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  const sandboxState = state[sandboxWritersStorePack.name];
  const FCMOperators = state[sandboxFCMOperatorsStorePack.name];
  const sandboxSettingsState = state[sandboxSettingsStorePack.name];
  const hasSettings = !!sandboxSettingsState.data.countries;
  const notificationsEntity = state[notificationsEntityStorePack.name];
  const notificationsLoading = !notificationsEntity || !notificationsEntity.data.ts || notificationsEntity.loading;
  const countryOptions = (sandboxSettingsState.data.countries || []).map(c => [c, c]);

  const refOptions = (sandboxSettingsState.data.refs || []).filter(c => c !== 'lr-writers.com').map(c => [c, c]);
  const highRefOptions = (sandboxSettingsState.data.refs || []).filter(c => c === 'lr-writers.com').map(c => [c, c]);

  countryOptions.sort();
  const profileEntity = state[profileStorePack.name];

  return {
    loading: sandboxState.loading || !hasSettings || sandboxSettingsState.loading,
    loadingNext: sandboxState.loadingNext,
    list: sandboxState.data.list,
    total: sandboxState.data.total,
    page: sandboxState.data.page,
    error: sandboxState.error,
    filter: sandboxState.filter,
    sorting: sandboxState.sorting,
    profile: profileEntity && profileEntity.data,
    countryOptions,
    notificationsLoading,
    refOptions,
    highRefOptions,
    fcmOperators: FCMOperators.data.fcmOperators || [], 
  };
};

const mapDispatchToProps = dispatch => ({
  load: () => {
    dispatch(sandboxWritersStorePack.getTableAction());
    dispatch(sandboxSettingsStorePack.getEntityAction());
  },
  loadNotifications: () => dispatch(notificationsEntityStorePack.getEntityActionIfNeeded()),
  loadNext: () => dispatch(sandboxWritersStorePack.getTableNextAction()),
  pluginSetConfig: config => dispatch(mainHubActions.addNewConfig(config)),
  openHiringApplicantsResults: (applicantsHiringResults, onRunApplicants, getApplicantsList) => dispatch(mainHubActions.addNewConfig(pluginConfigs.HireApplicantsResultsPlugin({ options: { hideHeaderIcons: true }, applicantsHiringResults, onRunApplicants, getApplicantsList }))),
  setSorting: (field, isAsc) => {
    dispatch(sandboxWritersStorePack.setTableSortingAction(field, isAsc));
  },
  setFilter: (field, value) => {
    dispatch(sandboxWritersStorePack.setTableFilterAction(field, value));
  },
  onResetFilters: () => {
    dispatch(sandboxWritersStorePack.resetTableFilterAction());
    dispatch(sandboxWritersStorePack.getTableAction());
  },
  updateStatus: (id, status) => dispatch(sandboxWritersStorePack.updateTableAction({ status, id }, { entityId: id })),
  addModal: component => dispatch(mainHubActions.pushModalComponent(component)),
  closeModal: () => dispatch(mainHubActions.popModalComponent()),
  loadFCMOperators: () =>  dispatch(sandboxFCMOperatorsStorePack.getEntityAction()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SandboxPage));
